<template>
  <b-card header="Cambia Password">
    <b-card-body>
      <validation-observer ref="rules">
        <b-form @submit.prevent="">

          <b-row class="mb-1">
            <b-col cols="12">
              <b-alert
                class="p-1"
                variant="warning"
                dismissible
                show
              >
                <p>La password deve essere lunga minimo 8 caratteri e contenere:</p>
                <ul>
                  <li>Un carattere maiuscolo.</li>
                  <li>Un carattere minuscolo.</li>
                  <li>Un numero.</li>
                  <li>Un carattere spceciale.</li>
                </ul>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <cs-validation
                label="Vecchia Password"
                rules="required"
              >
                <template slot-scope="props">

                  <cs-input
                    id="vecchia-password"
                    v-model="model.vecchiaPassword"
                    type="password"
                    label="Vecchia Password"
                    :state="props.errors.length === 0 ? null : false"
                  />

                </template>
              </cs-validation>

            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <cs-validation
                label="Nuova Password"
                rules="required|password:@Conferma Password"
              >
                <template slot-scope="props">

                  <cs-input
                    id="nuova-password"
                    v-model="model.nuovaPassword"
                    type="password"
                    label="Nuova Password"
                    :state="props.errors.length === 0 ? null : false"
                  />

                </template>
              </cs-validation>

            </b-col>

            <b-col cols="6">
              <cs-validation
                label="Conferma Password"
                rules="required"
              >
                <template slot-scope="props">

                  <cs-input
                    id="conferma-password"
                    v-model="model.confermaPassword"
                    type="password"
                    label="Conferma Password"
                    :state="props.errors.length === 0 ? null : false"
                  />

                </template>
              </cs-validation>

            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card-body>
    <!-- form -->
    <b-card-footer>
      <cs-submit-button :click="cambiaPassword">
        Cambia Password
      </cs-submit-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      model: {
        vecchiaPassword: '',
        nuovaPassword: '',
        confermaPassword: '',
      },
    }
  },
  methods: {
    // TODO: Cambio password i18n
    cambiaPassword() {
      this.$refs.rules.validate()
        .then(success => {
          if (success) {
            this.$remote.utenti.password_change(this.model)
              .then(() => this.showSuccessMessage('Password cambiata con successo'))
              .catch(res => this.showReposonseMessage(res))
          }
        })
    },
  },
}
</script>
